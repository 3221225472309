import React, { useState } from 'react';
import {NavLink, Navigate } from 'react-router-dom';
import UserService from "../../services/minkonto/UserService";
import AuthService from "../../services/minkonto/AuthService";

function Exit() {
    document.body.style.backgroundColor = '#002B1D';
    const [selectedOptionComp, setSelectedOptionComp] = useState(null);
    const [redirect, setRedirect] = useState(null);
    const [selectedOption, setSelectedOption] = useState("option1");
    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };
    const handleOptionChangeComp = (option) => {
        setSelectedOptionComp(option);
    };
    const handleStopSubscription = () => {
        const currentToken = AuthService.getCurrentToken();
        
            UserService.stopSubscription(currentToken.access_token).then(
              (subscription) => {
                setRedirect({ redirect: process.env.REACT_APP_MINKONTO_URL+'/stopsubscription' });
              },
              (error) => {
              }
            );
    }
    /*if (redirect) {
      return <Navigate  to={redirect.redirect} />;
    }*/
    return (
        <div>
            <div className="container-fluid">
                <div className="container content_green">
                    <div className="d-lg-none">
                        <div className="col-12"><h2 className="fm-login-heading">Er du helt sikker?</h2></div>
                        <p className="exit-text" >Vi er lei for at du forlater oss, og håper du vil komme tilbake for å høre lydbøker med Fabel ved en senere anledning.</p>
                        <p className="exit-text" >Vi legger ut nytt innhold hele tiden, slik at du vil ha mye nytt å lytte til når du kommer tilbake. For at vi skal kunne forbedre oss, setter vi stor pris på at du forteller oss hvorfor du stoppet abonnementet:</p>
                    </div>
                    <div className="d-none d-md-block">
                        <div className="col" style={{paddingTop: '45px'}}></div>
                        <div className="col-12"><h1 className="fm-login-heading">Er du helt sikker?</h1></div>
                        <p align="center">Vi er lei for at du forlater oss, og håper du vil komme tilbake for å høre lydbøker med Fabel ved en senere anledning.</p>
                        <p align="center">Vi legger ut nytt innhold hele tiden, slik at du vil ha mye nytt å lytte til når du kommer tilbake. For at vi skal kunne forbedre oss, setter vi stor pris på at du forteller oss hvorfor du stoppet abonnementet:</p>
                    </div>
                    <div className="col" style={{paddingTop: '28px'}}></div>
                </div>
                <div className="container content_green">
                    <div className="row">
                        <div className="col-md-6 offset-md-3 fm-form" align="center">

                            <div className="form-check fm-form-padding text-start col-10">
                              <input checked={selectedOption === "option1"} onChange={() => handleOptionChange("option1")} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                              <label className="form-check-label" for="flexRadioDefault1">Annet</label>
                            </div>
                            {selectedOption === "option1" && (
                            <div className="mb-3 fm-form-padding">
                              <textarea className="form-control exit-input" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                             )}

                            <div className="form-check fm-form-padding text-start col-10">
                              <input  checked={selectedOption === "option2"} onChange={() => handleOptionChange("option2")}  className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                              <label className="form-check-label" for="flexRadioDefault1">Hører for lite på lydbøker</label>
                            </div>
                            {selectedOption === "option2" && (
                            <div className="green-msg-box">
                                <p>Lydbøker lar deg utnytte tiden din bedre ved å lytte mens du gjør andre ting, som å kjøre, trene eller gjøre husarbeid. På den måten kan du konsumere litteratur når du ellers ikke ville hatt tid.</p>
                                <p><a href="#">Her kan du finne et abonnement som passer deg bedre.</a></p>
                            </div>
                            )}

                            <div className="form-check fm-form-padding text-start col-10">
                              <input  checked={selectedOption === "option3"} onChange={() => handleOptionChange("option3")}  className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                              <label className="form-check-label" for="flexRadioDefault1">For lite relevant innhold</label>
                            </div>
                            
                            
                            <div className="form-check fm-form-padding text-start col-10">
                              <input  checked={selectedOption === "option4"} onChange={() => handleOptionChange("option4")}    className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                              <label className="form-check-label" for="flexRadioDefault1">Det er komplisert</label>
                            </div>
                            {selectedOption === "option4" && (
                            <div className="green-msg-box">
                                <div className="form-check  text-start col-10">
                                  <input checked={selectedOptionComp === "option41"} onChange={() => handleOptionChangeComp("option41")} className="form-check-input form-check-input-comp" type="radio" name="flexRadioDefaultComp" id="flexRadioDefault1" />
                                  <label className="form-check-label-comp" for="flexRadioDefault1">Tekniske problemer med appen</label>
                                </div>
                                <div className="form-check  text-start col-10">
                                  <input checked={selectedOptionComp === "option42"} onChange={() => handleOptionChangeComp("option42")} className="form-check-input  form-check-input-comp" type="radio" name="flexRadioDefaultComp" id="flexRadioDefault1" />
                                  <label className="form-check-label-comp" for="flexRadioDefault1">&Aring; velge lydbok</label>
                                </div>
                                <div className="form-check  text-start col-10">
                                  <input checked={selectedOptionComp === "option43"} onChange={() => handleOptionChangeComp("option43")}  className="form-check-input  form-check-input-comp" type="radio" name="flexRadioDefaultComp" id="flexRadioDefault1" />
                                  <label className="form-check-label-comp" for="flexRadioDefault1">Annet</label>
                                </div>
                                {selectedOptionComp === "option43" && (
                                <div  className="mb-3  col-10 fm-form-padding">
                                    <input className="form-control  exit-input" type="text" />
                                </div>
                                )}
                            </div>
                            )}

                            <div className="form-check fm-form-padding text-start col-10">
                              <input  checked={selectedOption === "option5"} onChange={() => handleOptionChange("option5")}  className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                              <label className="form-check-label" for="flexRadioDefault1">For dyrt</label>
                            </div>
                            {selectedOption === "option5" && (
                            <div className="green-msg-box">
                                <p>Prisene v&aring;re er utformet for &aring; være s&aring; rimelige som mulig. Fra kun 149 kroner per med kan hele fire personer benytte tjenesten og lytte helt ubegrentset.</p>
                                <p><a href="#">Her kan du finne et abonnement som passer deg bedre.</a></p>
                            </div>
                            )}

                        </div>
                        
                        
                    </div>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3" align="center" >                        
                            <div className=" row"  align="center"  >
                                <div className="fm-form-btn-white col-6" align="center" >
                                <NavLink  to={process.env.REACT_APP_MINKONTO_URL+"/subscription"} >Avbryt</NavLink>
                                </div>
                                <div className="fm-form-btn-green  col-6" align="center">
                                <NavLink onClick={() => handleStopSubscription()} to={process.env.REACT_APP_MINKONTO_URL+"/stopsubscription"} >Stopp abonnementet</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                    
                </div>
                
                
                
                <div className="container">
                    <div className="row" >
                        <div className="col-12" style={{padding: '20px 0px'}}  align="center">
                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default Exit;
  