import {NavLink, Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import UserService from "../../services/minkonto/UserService";
import AuthService from "../../services/minkonto/AuthService";
import laodingImage from '../../images/loading.svg';

function MyAccount() {
    document.body.style.backgroundColor = '#002B1D';
    const [redirect, setRedirect] = useState(null);
    //const [userReady, setUserReady] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [subscription, setSubscription] = useState();
    //console.log(subscription);
    //const [subscriptionReady, setSubscriptionReady] = useState(false);
    const [showResetForm, setShowResetForm] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
   // const [currentPassword, setCurrentPassword] = useState("");
    const [smsCode, setSmsCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [hideButtonShowMsg, setHideButtonShowMsg] = useState(false);
    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
    };

    const [userMessageName, setUserMessageName] = useState({
      showMessage: false,
      message: "",
      messageType: "danger",
    });
    const [userMessagePassword, setUserMessagePassword] = useState({
      showMessage: false,
      message: "",
      messageType: "danger",
    });

   
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // Ensure day and month are displayed with leading zeros if needed
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;

      return `${formattedDay}.${formattedMonth}.${year}`;
    };

    useEffect(() => {
      const currentToken = AuthService.getCurrentToken();
      //console.log(currentToken);
      if (!currentToken) {
        AuthService.logout();
        setRedirect({ redirect: process.env.REACT_APP_MINKONTO_URL });
      } else {
        UserService.getUserFromToken(currentToken.access_token).then(
        (user) => {
          console.log("user", user);
          setFirstname(user.firstname);
          setLastname(user.lastname);
          setEmail(user.email);
          setIsChecked(user.newsletter);
          setCurrentUser(user);
         // setUserReady(true);
        },
        (error) => {
          AuthService.logout();
          setRedirect({ redirect: process.env.REACT_APP_MINKONTO_URL });
        });
    
        UserService.getSubscription(currentToken.access_token).then(
        (response) => {
          setSubscription(response);
         // setSubscriptionReady(true);
        },
        (error) => {
          
         // setSubscriptionReady(true);
        });
      }
    }, []);
    
    const handleChange = (e) => {
      const name = e.currentTarget.name;
      switch (name) {
        case "email":
          setEmail(e.currentTarget.value);
          break;
        case "firstname":
          setFirstname(e.currentTarget.value);
          break;
        case "lastname":
          setLastname(e.currentTarget.value);
          break;
        case "currentPassword":
          //setCurrentPassword(e.currentTarget.value);
          break;
        case "newPassword":
          setNewPassword(e.currentTarget.value);
          break;
        case "confirmNewPassword":
          setConfirmNewPassword(e.currentTarget.value);
          break;
        case "smsCode":
          setSmsCode(e.currentTarget.value);
          break;
        default:
          break;
      }
    };
    const handleSubmitName = () => {
      setUserMessageName({
        showMessage: false,
        message: "",
        messageType: "",
      });
      const userdata = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        newsletter: isChecked,

      };
      //console.log("userdata", userdata);
      const currentToken = AuthService.getCurrentToken();

      UserService.changeUser(currentToken.access_token, userdata).then(
        (response) => {
          UserService.getUserFromToken(currentToken.access_token).then(
          (user) => {
            setFirstname(user.firstname);
            setLastname(user.lastname);
            setEmail(user.email);
            setCurrentUser(user);
            //setUserReady(true);
          },
          (error) => {
            setRedirect({ redirect: "/" });
          });
          setUserMessageName({
            showMessage: true,
            message: "Ditt navn har blitt oppdatert",
            messageType: "alert-success",
          });
        },
        () => {
          setUserMessageName({
            showMessage: true,
            message: "Noe gikk galt, prøv igjen senere.",
            messageType: "alert-danger",
          });
        }
      );
    };
    
    const sendSMSCode = () => {
      setShowLoading(true);
      UserService.newSmsCode(currentUser.mobile).then(
      (response) => {
        setShowResetForm(true);
        setShowLoading(false);
      }, (error) => {
        setShowLoading(false);
        setUserMessagePassword({
          showMessage: true,
          message: "Ugyldig mobilnummer.",
          messageType: "alert-danger",
        });
      })
    }
    
    const handleSubmitPassword = () => {
    if (newPassword === confirmNewPassword) {
      //const userdata = { ...currentUser, password: newPassword };
      const currentToken = AuthService.getCurrentToken();
      UserService.changePassword(currentUser.mobile, smsCode, newPassword).then(
        (response) => {
          setCurrentUser(response);
          setHideButtonShowMsg(true);
          AuthService.logout();
        },
        () => {
          setUserMessagePassword({
            showMessage: true,
            message: "Noe gikk galt i endring av Passord",
            messageType: "alert-danger",
          });
        }
      );
    } else {
      setUserMessagePassword({
        showMessage: true,
        message: "Passordene matcher ikke.",
        messageType: "alert-danger",
      });
    }
  };
    /*if (redirect) {
      return <Navigate  to={redirect.redirect} />;
    }*/
    return (
        <div>
            <div className="container-fluid">
                <div className="container content_green">
                    <div className="col" style={{paddingTop: '45px'}}></div>
                    <div className="row  no-padding">
                        <div className="col-lg-6">
                            <div className="col-12 fm-accordion-textarea" >
                              <h1>Min konto</h1>
                              <p>
                                  {currentUser ? currentUser.firstname+' '+currentUser.lastname : null}<br />
                                  Mobilnummer: {currentUser ? currentUser.mobile : null}<br />
                                  E-post:  {currentUser ? currentUser.email : null}
                              </p>
                              
                              {subscription ? 
                              <p>
                                  <span>Aktiv abonnement: {subscription.product_id}</span><br />
                                  Neste betaling: {formatDate(subscription.next_billing_date)}
                              </p>
                              : '' } 
                            </div>
                            
                            <div className="col-12 col-sm-12 col-lg-10 fm-accordion-area d-sm-block d-lg-block">
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/subscription"}>
                                    <div className=" col-12 col-sm-12 col-lg-12 row fm-accordion-btn  ">
                                        <div className="col-10"><h1>Abonnement</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/minkonto"}>
                                    <div className=" col-12 col-sm-12 col-lg-12 row fm-accordion-btn fm-accordion-btn-active">
                                        <div className="col-10"><h1>Konto</h1></div>
                                        <div className="col-2"><h2>-</h2></div>
                                    </div>
                                </NavLink>
                                <div className="d-lg-none col-12 col-sm-12 col-lg-12 fm-accordion-content-mobile minkonto-tab-mobile">
                                    <div className="row no-padding item">
                                      <div className="col-10 col-sm-6 ">
                                        Brukeropplysninger
                                      </div>
                                      <div className="col-2 col-sm-6  edit-link " >
                                        <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/minkonto"}> Endre </NavLink>
                                      </div>
                                    </div>
                                    <div className="row">
                                    </div>
                                    <div className="row  no-padding  item">
                                      <div className="col-10 col-sm-6">
                                        Passord
                                      </div>
                                      <div className="col-2 col-sm-6 edit-link" >
                                       <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/minkonto"}> Endre </NavLink>
                                      </div>
                                    </div>
                                </div>
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/betaling"}>
                                    <div className=" col-12 col-sm-12 col-lg-12 row fm-accordion-btn">
                                        <div className="col-10"><h1>Betaling</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/kjopshistorikk"}>
                                    <div className=" col-12 col-sm-12 col-lg-12 row fm-accordion-btn">
                                        <div className="col-10"><h1>Kjøpshistorikk</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                
                            </div>
                           
                            

                        </div>
                        <div className="col-lg-6 d-none d-lg-block">
                            <div className="row fm-accordion-content align-middle">
                                <div className="col-10 my-account-fields" >
                                    <h1>Endre brukeropplysninger</h1>
                                    {userMessageName.showMessage ? (
                                      <div
                                        className={userMessageName.messageType + " alert card-alert"}
                                        role="alert"
                                      >
                                        {userMessageName.message}
                                      </div>
                                    ) : null}
                                    <div className="fm-login-form-label">
                                      E-POSTADRESSE
                                    </div>
                                    <div >
                                        <input type="text" name="email" readOnly={true} className="fm-login-form-input" onChange={handleChange} onKeyUp={handleChange} value={email} />
                                    </div>
                                    <div><br /></div>
                                    <div className="fm-login-form-label">
                                      FORNAVN
                                    </div>
                                    <div >
                                        <input type="text" name="firstname"  className="fm-login-form-input" onChange={handleChange}  onKeyUp={handleChange} value={firstname} />
                                    </div>
                                    <div><br /></div>
                                    <div className="fm-login-form-label">
                                      ETTERNAVN
                                    </div>
                                    
                                    <div >
                                        <input type="text" name="lastname"  className="fm-login-form-input" onChange={handleChange} onKeyUp={handleChange} value={lastname} />
                                    </div>
                                    <div><br /></div>
                                    <div className="form-check">
                                      <input checked={isChecked} className="form-check-input" type="checkbox" onChange={handleCheckboxChange} id="flexCheckDefault" />
                                      <div className="myaccount-checkbox-label" htmlFor="flexRadioDefault1">
                                       Ja takk! Jeg vil motta nyhetsbrev p&aring; e-post fra Fabel med anbefalinger, eksklusive og personlig tilpassede tips.
                                      </div>
                                    </div>
                                    <div align="center">
                                      <NavLink className="fm-login-btn" to=""  onClick={handleSubmitName} >Lagre</NavLink>
                                    </div>
                                    <div><br /></div>
                                    <div><br /></div>
                                    <div><br /></div>
                                    <h1>Endre passord</h1>
                                    {userMessagePassword.showMessage ? (
                                      <div
                                        class={userMessagePassword.messageType + " alert card-alert"}
                                        role="alert"
                                      >
                                        {userMessagePassword.message}
                                      </div>
                                    ) : null}
                                    {showResetForm ? 
                                    <>
                                      <div>
                                          <input type="text" className="fm-login-form-input" name="smsCode"  onChange={handleChange} onKeyUp={handleChange} placeholder="Sms-kode" />
                                      </div>
                                      <div><br /></div>
                                      <div >
                                          <input type="password"  className="fm-login-form-input" name="newPassword"  onChange={handleChange} onKeyUp={handleChange} placeholder="Nytt passord" />
                                      </div>
                                      <div><br /></div>
                                      
                                      <div >
                                          <input type="password"  className="fm-login-form-input" name="confirmNewPassword"  onChange={handleChange} onKeyUp={handleChange} placeholder="Bekreft passord"  />
                                      </div>
                                      <div align="center">
                                        {hideButtonShowMsg ? 
                                          <>
                                            <div id="handlesubmitpasswordmsg" className="handlesubmitpasswordmsg">Passordet er endret!</div>
                                          </>
                                          :
                                          <>
                                            <NavLink className="fm-login-btn" id="handlesubmitpassword" to=""  onClick={handleSubmitPassword} >Endre passord</NavLink>
                                          </>
                                        }
                                      </div>
                                    </> 
                                    : 
                                    <>
                                      {showLoading ? 
                                        <div>
                                        <img src={laodingImage} width={48} />
                                        </div> 
                                        : 
                                      <div>
                                        <input type="text" readOnly={true} className="fm-login-form-input" name="mobileNumber" value={currentUser ? currentUser.mobile : ''}  />
                                      </div>
                                      }

                                      <div align="center">
                                        <NavLink className="fm-login-btn" to=""  onClick={sendSMSCode} >Send sms-kode</NavLink>
                                      </div>
                                    </>

                                    }
                                    

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                
                
            </div>
        </div>
    )
}
export default MyAccount;
  