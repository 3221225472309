import {NavLink } from 'react-router-dom';
import React from 'react';
function VippsPayment() {
   
document.body.style.backgroundColor = '#002B1D';
    return (
        <div>
            <div className="container-fluid">
                <div className="container content_green">
                    <div className="col" style={{paddingTop: '45px'}}></div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="col-12 fm-accordion-textarea" >
                                <h1>Min konto</h1>
                                <p>
                                    Martine Hansen<br />
                                    Mobilnummer: 90 64 99 23<br />
                                    E-post: martinebratlihansen@gmail.com
                                </p>
                                
                                <p>
                                    <span>Aktiv abonnement: Fabel Én Bok</span><br />
                                    Neste betaling: 23.09.2023
                                </p>
                                
                                
                            </div>
                            
                            <div className="col-12 fm-accordion-area d-none d-lg-block">
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/subscription"}>
                                    <div className="row fm-accordion-btn  ">
                                        <div className="col-10"><h1>Abonnement</h1></div>
                                        <div className="col-2"><h2>-</h2></div>
                                    </div>
                                </NavLink>
                                
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/minkonto"}>
                                    <div className="row fm-accordion-btn ">
                                        <div className="col-10"><h1>Konto</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/betaling"}>
                                    <div className="row fm-accordion-btn fm-accordion-btn-active">
                                        <div className="col-10"><h1>Betaling</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/kjøpshistorikk"}>
                                    <div className="row fm-accordion-btn">
                                        <div className="col-10"><h1>Kjøpshistorikk</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                
                            </div>
                           
                            <div className="col-12 fm-accordion-area d-sm-block d-xs-block d-lg-none d-xxl-none">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Abonnement
                                      </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Konto
                                      </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                       Betaling
                                      </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                    </div>
                                  </div>
                                  
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                       Kjøpshistorikk
                                      </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                    </div>
                                  </div>
                                  
                                  
                                </div>
                              </div>

                        </div>
                        <div className="col-lg-6 d-none d-lg-block">
                            <div className="row fm-accordion-content align-middle">
                                <div className="col-10 my-account-fields" >
                                    <h1>Betaling</h1>
                                    <div className="payment-reg">VALGT BETALINGSMETODE</div>
                                    
                                    <NavLink to="" className="vipps-button"  ></NavLink>
                                    <div><br /></div>
                                    <div className="payment-reg">ENDRE BETALINGSMETODE</div>
                                    <NavLink className="fm-bott-button" to={"/betalingskort"} >Legg til nytt betalingskort</NavLink>
                                    
                                    <div><br /></div>
                                    <div className="payment-reg">Betal med mobilbetaling</div>
                                    <NavLink className="strex-button"  to={"/strexbetaling"}  ></NavLink>
                                    <div><br /></div>
                                    <div className="payment-reg-small">Velger du &aring; betale med mobileregning trenger du ikke &aring; registere kortinformasjon.<br />
                                    Abonnementet vil bli belaster mobilregningen din.<br />
                                    Oversikt over alle transaksjoner finner du p&aring; <NavLink target="_blank" rel="noopener noreferrer" className="boldundlinks" to={"https://strex.no"}>strex.no</NavLink></div>
                                    <div><br /></div>
                                    <div><br /></div>
                                    <h1>Gavekort/Verdikode</h1>
                                    <div className="payment-reg">Her kan du l&oslash;se inn gavekort eller verdikode.<br />
                                    Abonnementet starter automatisk ved innl&oslash;ing av koden.</div>
                                     <div><br /></div>
                                    <div >
                                        <input type="text"  className="fm-login-form-input input-round-corner" />
                                    </div>
                                    <div align="center">
                                      <NavLink className="fm-login-btn" to={process.env.REACT_APP_MINKONTO_URL+"/logg-inn"} >L&oslash;s inn</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                
                
            </div>
        </div>
    )
}
export default VippsPayment;
  