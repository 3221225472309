import React, { useState, useEffect } from "react";
import logoImage from '../../images/logo.png';
import searchImage from '../../images/search.png';
import menuImage from '../../images/menu.png';
import {NavLink} from 'react-router-dom';
import "../../styles/minkonto/bootstrap.min.css";
import AuthService from "../../services/minkonto/AuthService";
function Header() {
	const [authenticated, setAuthenticated] = useState(false);
	useEffect(() => {
		const currentToken = AuthService.getCurrentToken();
		if (currentToken) {
			setAuthenticated(true);
		}
	}, []);
	const handleClick = (linkReference) => {
	    switch (linkReference) {
	      case "kundeservice":
	        window.location.href = "https://fabel.no/kundeservice/";
	        break;
	      case "registrering":
	        window.location.href = process.env.REACT_APP_FABEL_REGISTRATION_URL + "produkter/";
	        break;
	      case "log-out":
	        AuthService.logout();
	        //window.location.href = '/'
	        //window.location.replace(
	         // process.env.REACT_APP_FABEL_WP_URL + "SSO/sso_login_v2.php?url=" + process.env.REACT_APP_FABEL_WP_URL
	        //);
	        window.location.href = process.env.REACT_APP_MINKONTO_URL+"/logg-inn";
	        //history.push("logg-inn");
	        break;
	      case "min-side":
	        window.location.reload();
	        break;
	      default:
	        break;
	    }
	  };
    return (
        <div>
           <div className="container-fluid fm-header d-none d-lg-block">
	        	<div className="container">
	        		<div>
	        		
	        		</div>
	            	<div className="row fm-padtop">
	                	<div className="col-lg-2 col-sm-3" ><NavLink to={process.env.REACT_APP_MINKONTO_URL+""}><img src={logoImage} /></NavLink></div>
	                	<div className="col-lg-1 col-sm-3 fm-hambuuger" ><a href="#"><img src={menuImage} /></a></div>
	                	<div className="col-lg-9">
	                    	<div className="row menu text-end">
	                        	<div className="col-lg-9"><NavLink className="fm-kundeservice-btn" to={"https://fabel.no/kundeservice/"} >KUNDESERVICE</NavLink></div>
	                        	{authenticated ? (
				                	<div className="col-lg-3"><NavLink className="fm-logout-btn" to="" onClick={() => handleClick("log-out")} >LOGG UT</NavLink></div>
				              	) : (
				                	<div className="col-lg-3"><NavLink className="fm-logout-btn" to={process.env.REACT_APP_MINKONTO_URL+"/logg-inn"} >LOGG INN</NavLink></div>
				              	)}
	                        	
	                        </div>

	                    </div>
	                </div>
	            </div>
	        </div>
	        <div className="container-fluid fm-header-mob d-sm-block d-xs-block d-lg-none d-xxl-none">
	        	<div className="container">
	            	<div className="row fm-padtop-mob">
	                	<div className="col-2" ><a href="#"><img src={searchImage} width="41" height="41" /></a></div>
	                	<div className="col-8" align="center"><NavLink to={process.env.REACT_APP_MINKONTO_URL+""}><img src={logoImage} width="111" height="24" /></NavLink></div>
	                	<div className="col-2" ><a href="#"><img src={menuImage} width="49" height="49" /></a></div>
	                </div>
	            </div>
	        </div>
        </div>
    )
}
export default Header;
  