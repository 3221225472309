import {NavLink, useNavigate, Navigate  } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import UserService from "../../services/minkonto/UserService";
import AuthService from "../../services/minkonto/AuthService";

function Subscription() {
    let history = useNavigate();
    document.body.style.backgroundColor = '#002B1D';
    const [redirect, setRedirect] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [subscription, setSubscription] = useState();
    //console.log(subscription);

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // Ensure day and month are displayed with leading zeros if needed
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;

      return `${formattedDay}.${formattedMonth}.${year}`;
    };
    useEffect(() => {
      const currentToken = AuthService.getCurrentToken();
      if (!currentToken) {
        setRedirect({ redirect: process.env.REACT_APP_MINKONTO_URL });
      } else {
        

        UserService.getUserFromToken(currentToken.access_token).then(
        (user) => {
          setCurrentUser(user);
         // setUserReady(true);
        },
        (error) => {
          setRedirect({ redirect: process.env.REACT_APP_MINKONTO_URL });
        });
        
        UserService.getSubscription(currentToken.access_token).then(
        (response) => {
          setSubscription(response);
          //setSubscriptionReady(true);
        },
        (error) => {
         
         // setSubscriptionReady(true);
        });
        
      }
    }, [history]);
    console.log(currentUser, subscription);
    /*if (redirect) {
      return <Navigate  to={redirect.redirect} />;
    }*/
    return (
        <div>
            <div className="container-fluid">
            
                <div className="container content_green">
                    <div className="col" style={{paddingTop: '45px'}}></div>
                    <div className="row no-padding">
                        <div className="col-lg-6">
                            <div className="col-12 fm-accordion-textarea" >
                              <h1>Min konto</h1>
                              <p>
                                  {currentUser ? currentUser.firstname+' '+currentUser.lastname : null}<br />
                                  Mobilnummer: {currentUser ? currentUser.mobile : null}<br />
                                  E-post:  {currentUser ? currentUser.email : null}
                              </p>
                              {subscription ? 
                              <p>
                                  <span>Aktiv abonnement: {subscription.product_id}</span><br />
                                  Neste betaling: {formatDate(subscription.next_billing_date)}
                              </p>
                              : '' }
                            </div>
                            
                            <div className="col-12 col-sm-12 col-lg-10 fm-accordion-area d-sm-block d-lg-block">
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/subscription"}>
                                    <div className=" col-12 col-sm-12 col-lg-12 row fm-accordion-btn fm-accordion-btn-active ">
                                        <div className="col-10"><h1>Abonnement</h1></div>
                                        <div className="col-2"><h2>-</h2></div>
                                    </div>
                                </NavLink>
                                <div className="d-lg-none col-12 col-sm-12 col-lg-12 fm-accordion-content-mobile">
                                    <p className="p1">NÅVÆRENDE ABONNEMENT</p>
                                    <p className="p2">Fabel Familie Pluss: 229 kr/mnd</p>
                                    <p className="p3">4 profiler kan lytte samtidig<br />Ubegrenset tilgang til alle lydbøker</p>
                                    
                                    <p className="p4">NESTE BETALINGSDATO<br /><strong>23.09.2023</strong></p>
                                    
                                    <NavLink className="fm-bott-button" to={"/changesub"} >Administrer abonnement</NavLink>
                                </div>
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/minkonto"}>
                                    <div className=" col-sm-12 row fm-accordion-btn">
                                        <div className="col-10"><h1>Konto</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/betaling"}>
                                    <div className=" col-sm-12 row fm-accordion-btn">
                                        <div className="col-10"><h1>Betaling</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/kjopshistorikk"}>
                                    <div className=" col-sm-12 row fm-accordion-btn">
                                        <div className="col-10"><h1>Kjøpshistorikk</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                
                            </div>
                            
                            <div className="col-12 fm-accordion-area d-none d-xs-none d-lg-none d-xxl-none">

                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Abonnement
                                      </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">
                                        <h1>Abonnement</h1>
                                        <p>NÅVÆRENDE ABONNEMENT</p>
                                        <p>Fabel Familie Pluss: 229 kr/mnd</p>
                                        <p>4 profiler kan lytte samtidig<br />Ubegrenset tilgang til alle lydbøker</p>
                                        <p>&nbsp;</p>
                                        <p>NESTE BETALINGSDATO<br /><strong>23.09.2023</strong></p>
                                        <p>&nbsp;</p>
                                        <NavLink className="fm-bott-button" to={process.env.REACT_APP_MINKONTO_URL+"/changesub"} >Administrer abonnement</NavLink>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Konto
                                      </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                       Betaling
                                      </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                    </div>
                                  </div>
                                  
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                       Kjøpshistorikk
                                      </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                    </div>
                                  </div>
                                  
                                  
                                </div>
                              </div>

                        </div>
                        <div className="col-lg-6 d-none d-lg-block">
                            <div className="row fm-accordion-content align-middle">
                                <div className="col-12" style={{paddingTop: '80px'}}>
                                    <h1>Abonnement</h1>
                                    <p>NÅVÆRENDE ABONNEMENT</p>
                                    <p>Fabel Familie Pluss: 229 kr/mnd</p>
                                    <p>4 profiler kan lytte samtidig<br />Ubegrenset tilgang til alle lydbøker</p>
                                    <p>&nbsp;</p>
                                    <p>NESTE BETALINGSDATO<br /><strong>23.09.2023</strong></p>
                                    <p>&nbsp;</p>
                                    <div className=" row"  align="center"  >
                                        <div className="fm-bott-button col-6" align="center" >
                                        <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/changesub"} >Administrer abonnement</NavLink>
                                        </div>
                                        <div className="fm-form-btn-green  col-6" align="center">
                                        <NavLink   to={process.env.REACT_APP_MINKONTO_URL+"/"} >Velg ny lydbok</NavLink>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                
                
            </div>
        </div>
    )
}
export default Subscription;
  