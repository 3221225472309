import {NavLink, useNavigate  } from 'react-router-dom';
import React, { useState } from 'react';
import AuthService from "../../services/minkonto/AuthService";
import ValidateInput from "../../services/minkonto/ValidateInput";
import UserService from "../../services/minkonto/UserService";

function Login() {
    document.body.style.backgroundColor = '#002B1D';
    let navigate  = useNavigate();
    const [submitState, setSubmitState] = useState({
        submitted: false,
        isError: false,
        message: "",
    });
    const [loginCredError, setloginCredError] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });
    const handleSubmit = (event) => {
        event.preventDefault();
        const stateUpdate = ValidateInput(formData.username, formData.password);
        setSubmitState(stateUpdate);
        if (stateUpdate.isError === false && stateUpdate.submitted === true) {
            AuthService.login('47'+formData.username, formData.password).then(
                (response) => {
                    const currentToken = AuthService.getCurrentToken();
                    UserService.getSubscription(currentToken.access_token).then(
                    (response) => {
                       //navigate(process.env.REACT_APP_MINKONTO_URL+"/subscription");
                       navigate("/minkonto/subscription");
                       //window.location.reload();
                    })
                    .catch((error) => {
                        navigate("/minkonto/subscription");
                        //navigate(process.env.REACT_APP_MINKONTO_URL+"/subscription");
                        //console.log("error: " + error);
                      //history.push("/subscription");
                    });
                },
                (error) => {
                  setloginCredError(true);
                  
                }
            );
        }
    }
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };
    return (
        <div>
            <div className="container-fluid">
                <div className="container">
                    <div className="col" style={{paddingTop: '45px'}}></div>
                    <div className="col-12"><h1 className="fm-login-heading">LOGG INN</h1></div>
                    <div className="col" style={{paddingTop: '28px'}}></div>
                </div>
                <div className="container content_green">
                    <div className="row" align="center">
                        
                        
                        <div className="col-lg-4 col-sm-12" align="center">
                            
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            {loginCredError ? (
                              <div className="alert alert-danger fabel-alert" role="alert">
                                Glemt passordet ditt, klikk <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/glemtpassord"} style={{cursor:'pointer', color: '#0d6efd'}} >her</NavLink> for å tilbakestille.
                              </div>
                              ) : null}
                            {submitState.isError ? (
                              <div className="alert alert-danger fabel-alert" role="alert">
                                {submitState.message}
                              </div>
                            ) : null}
                            <form id="product-page-container-main-form"  onSubmit={handleSubmit}>
                            <div className="login-form-fields"  align="left">
                                <div className="fm-login-form-label">
                                    MOBILNUMMER
                                </div>
                                <div >
                                    <input type="text" className="fm-login-form-input" name="username" value={formData.username} onChange={handleChange} />
                                </div>
                                <div><br /></div>
                                <div className="fm-login-form-label">
                                    PASSORD
                                </div>
                                <div >
                                    <input type="password"  className="fm-login-form-input" name="password" value={formData.password} onChange={handleChange} />
                                </div>
                                <div align="center">
                                <button  className="fm-login-btn" type="submit">Logg inn</button>
                                </div>
                                <div align="center">
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/glemtpassord"} className="fm-forgot-pass-link" >Glemt passord?</NavLink>
                                </div>
                            </div>
                            </form>
                        </div>

                        <div className="col-lg-4 col-sm-12">
                            
                        </div>
                    </div>
                    
                    
                    
                </div>
                
                
                
                <div className="container">
                    <div className="row" >
                        <div className="col-12" style={{padding: '20px 0px'}}  align="center">
                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default Login;
  