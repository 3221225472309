import {NavLink, useNavigate, Navigate  } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import UserService from "../../services/minkonto/UserService";
import AuthService from "../../services/minkonto/AuthService";
import {Elements, CardExpiryElement, CardNumberElement, CardCvcElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const StripeWidget = ({currentUser, setCurrentUser}) => {
    return (
    <Elements stripe={stripePromise}>
        <PaymentCard />
    </Elements>
    )
}

function PaymentCard() {
    let history = useNavigate();
    document.body.style.backgroundColor = '#002B1D';
    const [redirect, setRedirect] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [subscription, setSubscription] = useState();
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // Ensure day and month are displayed with leading zeros if needed
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;

      return `${formattedDay}.${formattedMonth}.${year}`;
    };
    const elements = useElements();
    const stripe = useStripe();
    
    const handleCardPayment = () => {
        const currentToken = AuthService.getCurrentToken();

        UserService.stripeSetupIntent(currentToken.access_token)
            .then(response => {
            const clientSecret = response.client_secret
            const cardElement = elements.getElement(CardNumberElement);
            console.log(clientSecret, cardElement);
            stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                  card: cardElement,
                },
              })
              .then((result) => {
                if (result.error) {
                  
                  console.log("payment/card error: "+JSON.stringify(result));
                } else {
                  
                  UserService.stripePaymentMethod(currentToken.access_token, result.setupIntent.payment_method)
                    .then(response => {
                        window.location.reload();
                       // currentUser = {...currentUser, payment_type: 3}
                        //setCurrentUser(currentUser)
                       
                    }, (error) => {
                        console.log("stripePaymentMethod error: "+JSON.stringify(error));
                       
                    })
                  
                }
              });

        }, () => {
            
        })
    }
    const CARD_ELEMENT_OPTIONS = {
      style: {
        base: {
          fontFamily: 'FilsonProBold, sans-serif',
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#002B1D',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      },
    };

    useEffect(() => {
      const currentToken = AuthService.getCurrentToken();
      if (!currentToken) {
        setRedirect({ redirect: process.env.REACT_APP_MINKONTO_URL });
      } else {
        

        UserService.getUserFromToken(currentToken.access_token).then(
        (user) => {
          setCurrentUser(user);
         // setUserReady(true);
        },
        (error) => {
          setRedirect({ redirect: process.env.REACT_APP_MINKONTO_URL });
        });
        
        UserService.getSubscription(currentToken.access_token).then(
        (response) => {
          setSubscription(response);
          //setSubscriptionReady(true);
        },
        (error) => {
         
         // setSubscriptionReady(true);
        });
        
      }
    }, [history]);
    /*if (redirect) {
      return <Navigate  to={redirect.redirect} />;
    }*/
    return (
        <div>
            <div className="container-fluid">
                <div className="container content_green">
                    <div className="col" style={{paddingTop: '45px'}}></div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="col-12 fm-accordion-textarea" >
                              <h1>Min konto</h1>
                              <p>
                                  {currentUser ? currentUser.firstname+' '+currentUser.lastname : null}<br />
                                  Mobilnummer: {currentUser ? currentUser.mobile : null}<br />
                                  E-post:  {currentUser ? currentUser.email : null}
                              </p>
                              {subscription ? 
                              <p>
                                  <span>Aktiv abonnement: {subscription.product_id}</span><br />
                                  Neste betaling: {formatDate(subscription.next_billing_date)}
                              </p>
                              : '' }
                            </div>
                            
                            <div className="col-12 fm-accordion-area d-none d-lg-block">
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/subscription"}>
                                    <div className="row fm-accordion-btn  ">
                                        <div className="col-10"><h1>Abonnement</h1></div>
                                        <div className="col-2"><h2>-</h2></div>
                                    </div>
                                </NavLink>
                                
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/minkonto"}>
                                    <div className="row fm-accordion-btn ">
                                        <div className="col-10"><h1>Konto</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/betaling"}>
                                    <div className="row fm-accordion-btn fm-accordion-btn-active">
                                        <div className="col-10"><h1>Betaling</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/kjøpshistorikk"}>
                                    <div className="row fm-accordion-btn">
                                        <div className="col-10"><h1>Kjøpshistorikk</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                
                            </div>
                           
                            <div className="col-12 fm-accordion-area d-sm-block d-xs-block d-lg-none d-xxl-none">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Abonnement
                                      </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Konto
                                      </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                       Betaling
                                      </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                    </div>
                                  </div>
                                  
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                       Kjøpshistorikk
                                      </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                    </div>
                                  </div>
                                  
                                  
                                </div>
                              </div>

                        </div>
                        <div className="col-lg-6 d-none d-lg-block">
                            <div className="row fm-accordion-content align-middle">
                                <div className="col-10 my-account-fields" >
                                    <h1>Legg till betalingskort</h1>
                                    <div >
                                        <CardNumberElement options={CARD_ELEMENT_OPTIONS}  className="fm-login-form-input" />
                                    </div>
                                    <div>
                                       
                                        <div style={{float:'left', width: '55%'}}>
                                        <CardExpiryElement options={CARD_ELEMENT_OPTIONS}  className="fm-login-form-input" />
                                        </div>
                                        <div style={{float:'left', width: '2%'}}>
                                        </div>
                                        <div style={{float:'right', width: '43%'}}>
                                        <CardCvcElement options={CARD_ELEMENT_OPTIONS}  className="fm-login-form-input"  />
                                        </div>
                                        
                                    </div>
                                   
                                    <div><br /></div>
                                    
                                    <div align="center">
                                      <NavLink to="" className="fm-login-btn" onClick={() => handleCardPayment() } >Bekreft</NavLink>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                
                
            </div>
        </div>
    )
}
export default StripeWidget;
  